<template>
    <div class="login">
        <div class="ta-left logo" @click="$router.push({name:'home'})">
            <img src="@/assets/logo.png" alt="">
        </div>
        <form class="form">
            <a-form-item>
                <span class="label" slot="label">{{$t('login.label.account')}}</span>
                <a-input class="bg-sec" size="large" v-model="address"  />
            </a-form-item>
            <a-form-item>
                <span class="label" slot="label">{{$t('login.label.password')}}</span>
                <a-input-password autocomplete="false" id="password" class="bg-sec" size="large" ref="password" v-model="password" />
            </a-form-item>
            <div class="btn-primary mg30" @click="submit">
                {{$t('common.login_btn')}}
            </div>
            <div class="flex color-gray">
                <span @click="forgetPass">{{$t('login.forget')}}</span>
                <span @click="goRegister">{{$t('common.register_btn')}}</span>
            </div>
        </form>
    </div>
</template>
<script>

export default {
    name: 'Login',
    data(){
        return {
            address: '',
            password: '',
            disableAddressIpt: true,
            loading: false,
        }
    },
    components:{
    },
    mounted(){
        let _this = this;
        let obj = setInterval(async ()=>{
            if (window.walletAddress) {
                clearInterval(obj)
                _this.address = window.walletAddress;
            }
        }, 10);
    },
    methods:{
        submit(){
            return;
            // this.loading = true;
            // let _this = this;
            // if(!this.address || !this.password){
            //     this.$message.error(this.$t('login.tips.iptError'));
            //     return;
            // }
            // let data = {
            //     address: this.address,
            //     password: this.password
            // };
            // this.$http.call(this.$http.api.login,{data:data}).then(function(res){
            //     let resp = res.data;
            //     if(resp.code=='200'){
            //         _this.$store.commit('setToken', resp.data.token);
            //         _this.$store.commit('setUserinfo', resp.data.user);
            //         _this.$router.push({name:'home'});
            //     }else{
            //         _this.$message.error(resp.data);
            //     } 
            // },function(res){
            //     console.log('something goes wrong...');
            //     console.log(res);
            // }).then(()=>{
            //     _this.loading = false
            // });
        },
        goRegister(){
            this.$router.push({name:'register'});
        },
        forgetPass(){
            this.$message.info(this.$t('login.tips.forget'));
        },
    }
}
</script>
<style lang="less" scoped>
.login{
    width: 100%;
    max-width: 640px;
    margin:0 auto;
    padding: 2rem;
    .logo{
        img{
            height: 30px;
        }
    }
    .form{
        padding-top: 50px;
    }
    .label{
        color: @white;
    }
}


</style>
